const antiShake = (fn, time = 1000) => {
    let timer = null
    return function (...args) {
        if (timer) {
            clearTimeout(timer)
            timer = null
        }
        timer = setTimeout(() => {
            fn.apply(this, args)
            clearTimeout(timer)
            timer = null
        }, time)
    }
}

export default antiShake;